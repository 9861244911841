/* @flow */

import { useDispatch } from 'react-redux';

import useSidebar from 'src/shared/app/base/component/template/hooks/useSidebar';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';

import useVirtualBraindate from 'plugin/virtual-braindate/hook/useVirtualBraindate';

export default function useRouteConfig(): Object {
  const { areEmailNotificationsEnabled, isSignupEnabled } =
    useFeatureDecisions();
  const dispatch = useDispatch();
  const isVirtualBraindateEnabled = useVirtualBraindate();

  const { hasSidebar } = useSidebar();

  return {
    dispatch,
    areEmailNotificationsEnabled,
    isSignupEnabled,
    isVirtualBraindateEnabled,
    hasSidebar,
  };
}
