/* @flow */

import {
  accessibilityCompatibleFeature,
  cheerPlugin,
  commentsFeature,
  conversationFormatsPlugin,
  customMenuPlugin,
  doubleDutchPlugin,
  drawerMenuFeature,
  emailPlugin,
  fishbowlPlugin,
  githubProfileFeature,
  googleSyncPlugin,
  helpFeature,
  hideAccountPrefsFeature,
  hideTopBarOnScroll,
  iframeIntegrationPlugin,
  inspirationTopicsFeature,
  klikPlugin,
  lightThemeFeature,
  marketPromptsFeature,
  outlookSyncPlugin,
  peopleTabDisabled,
  provideFeedbackSectionFeature,
  pushNotificationsFeature,
  reactionsCountFeature,
  signupPlugin,
  slackPlugin,
  smsPhoneReminderWidget,
  smsPlugin,
  ssoPlugin,
  superGroupPlugin,
  topicApprovalPlugin,
  topicCardCustomizationPlugin,
  topicReactionsFeature,
  topicRecommendationFeature,
  topicSuggestionsFeature,
  transcribePlugin,
  videoCallPlugin,
  virtualBraindateRecordingPlugin,
} from 'src/shared/app/feature/settings/featureSettings';

export function createFeatureRouter() {
  let internalConfig = {};

  return {
    setFeature(featureName: string, isEnabled: boolean): void {
      internalConfig[featureName] = isEnabled;
    },
    isFeatureEnabled(featureName: string): boolean {
      return internalConfig[featureName];
    },
    reset(): void {
      internalConfig = {};
    },
  };
}

export function createFeatureDecisions(featureRouter: Object): {
  [key: string]: () => boolean,
} {
  return {
    // Market
    areMarketPromptsEnabled() {
      return featureRouter.isFeatureEnabled(marketPromptsFeature);
    },

    // Signup
    isSignupEnabled() {
      return featureRouter.isFeatureEnabled(signupPlugin);
    },
    // Profile
    isKlikIntegrationEnabled() {
      return featureRouter.isFeatureEnabled(klikPlugin);
    },
    isGithubProfileEnabled() {
      return featureRouter.isFeatureEnabled(githubProfileFeature);
    },
    // Preferences
    isAccountPrefsSectionHidden() {
      return featureRouter.isFeatureEnabled(hideAccountPrefsFeature);
    },
    // Availability
    isGoogleSyncEnabled() {
      return featureRouter.isFeatureEnabled(googleSyncPlugin);
    },
    isOutlookSyncEnabled() {
      return featureRouter.isFeatureEnabled(outlookSyncPlugin);
    },
    // Notifications
    areEmailNotificationsEnabled() {
      return featureRouter.isFeatureEnabled(emailPlugin);
    },
    areSMSNotificationsEnabled() {
      return featureRouter.isFeatureEnabled(smsPlugin);
    },
    isNotificationPromptShown() {
      return featureRouter.isFeatureEnabled(emailPlugin);
    },
    // Third party integration
    isDoubleDutchIntegrationEnabled() {
      return featureRouter.isFeatureEnabled(doubleDutchPlugin);
    },
    isSlackIntegrationEnabled() {
      return featureRouter.isFeatureEnabled(slackPlugin);
    },
    isIframeIntegrationEnabled() {
      return featureRouter.isFeatureEnabled(iframeIntegrationPlugin);
    },
    // Help
    isHelpEnabled() {
      return featureRouter.isFeatureEnabled(helpFeature);
    },
    // Navigation
    hasCustomMenu() {
      return featureRouter.isFeatureEnabled(customMenuPlugin);
    },
    // Topic Approval / (ex: Moderation)
    isTopicApprovalEnabled() {
      return featureRouter.isFeatureEnabled(topicApprovalPlugin);
    },
    // Light theme
    // @TODO - Remove this feature
    useLightTheme() {
      return featureRouter.isFeatureEnabled(lightThemeFeature);
    },

    // Video calls
    isVideoCallEnabled() {
      return featureRouter.isFeatureEnabled(videoCallPlugin);
    },

    isProvideFeedbackSectionEnabled() {
      return featureRouter.isFeatureEnabled(provideFeedbackSectionFeature);
    },

    isConversationFormatEnabled() {
      return featureRouter.isFeatureEnabled(conversationFormatsPlugin);
    },

    isFishbowlEnabled() {
      return featureRouter.isFeatureEnabled(fishbowlPlugin);
    },
    isSuperGroupEnabled() {
      return featureRouter.isFeatureEnabled(superGroupPlugin);
    },
    isSSOEnabled() {
      return featureRouter.isFeatureEnabled(ssoPlugin);
    },
    isVirtualBraindateRecordingsEnabled() {
      return featureRouter.isFeatureEnabled(virtualBraindateRecordingPlugin);
    },
    isCheerEnabled() {
      return featureRouter.isFeatureEnabled(cheerPlugin);
    },
    isAccessibilityCompatibleEnabled() {
      return featureRouter.isFeatureEnabled(accessibilityCompatibleFeature);
    },
    isTranscribeFeatureEnabled() {
      return featureRouter.isFeatureEnabled(transcribePlugin);
    },
    isPeopleTabDisabled() {
      return featureRouter.isFeatureEnabled(peopleTabDisabled);
    },
    isRecommendationFeatureEnabled() {
      return featureRouter.isFeatureEnabled(topicRecommendationFeature);
    },
    isSmsPhoneReminderWidgetEnabled() {
      return featureRouter.isFeatureEnabled(smsPhoneReminderWidget);
    },
    isTopicCardCustomizationPluginEnabled() {
      return featureRouter.isFeatureEnabled(topicCardCustomizationPlugin);
    },
    shouldHideTopBarOnScroll() {
      return featureRouter.isFeatureEnabled(hideTopBarOnScroll);
    },
    isInspirationTopicsEnabled() {
      return featureRouter.isFeatureEnabled(inspirationTopicsFeature);
    },
    isDrawerMenuEnabled() {
      return featureRouter.isFeatureEnabled(drawerMenuFeature);
    },
    arePushNotificationsEnabled() {
      return featureRouter.isFeatureEnabled(pushNotificationsFeature);
    },
    areTopicReactionsEnabled() {
      return featureRouter.isFeatureEnabled(topicReactionsFeature);
    },
    areCommentsEnabled() {
      return featureRouter.isFeatureEnabled(commentsFeature);
    },
    showReactionsCountFeatureEnabled() {
      return featureRouter.isFeatureEnabled(reactionsCountFeature);
    },
    isTopicSuggestionsFeatureEnabled() {
      return featureRouter.isFeatureEnabled(topicSuggestionsFeature);
    },
  };
}
