/* @flow */

import { memo, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Tooltip, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { useFocusWithin } from 'react-aria';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { getUserFullName } from '@braindate/domain/lib/user/util';
import { lap } from '@braindate/ui/lib/token/mediaQuery';
import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';

import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  availabilityRoute,
  ownTopicsRoute,
  preferencesRoute,
  profileEditRoute,
  profileRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { TOUR_SELECTOR_KEYS } from 'src/shared/app/coachmark/config/tourConfig';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import messages from 'src/shared/app/nav/l10n/navigationL10n.json';
import UserAvatar, {
  small,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import useSelf from 'src/shared/domain/user/hook/useSelf';
import ChevronIcon from 'src/shared/ui/component/icon/ChevronIcon';

import styles from './DrawerMenuButton.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  forwardedRef?: { current: null | HTMLElement },
  isMenuOpened: boolean,
  menuId?: string,
  setIsMenuOpened: (isOpened: boolean) => void,
};

const DrawerMenuButton = ({
  forwardedRef,
  menuId,
  isMenuOpened,
  setIsMenuOpened,
}: Props) => {
  const self = useSelf();
  const intl = useIntl();
  const isDesktop = useMediaQuery(lap);
  const { isDrawerMenuEnabled } = useFeatureDecisions();
  const classes = useStyles({ isDrawerMenuEnabled });
  const { pathname } = useLocation();

  const [isFocused, setIsFocused] = useState(false);

  const { focusWithinProps } = useFocusWithin({
    onFocusWithin: () => setIsFocused(true),
    onBlurWithin: () => setIsFocused(false),
  });

  const [
    preferencesPath,
    profilePath,
    ownTopicsPath,
    availabilityPath,
    profileEditPath,
  ] = useRoutes(
    preferencesRoute,
    profileRoute,
    ownTopicsRoute,
    availabilityRoute,
    profileEditRoute,
  );

  const isProfileActive = [
    preferencesPath,
    profilePath,
    ownTopicsPath,
    availabilityPath,
    profileEditPath,
  ].includes(pathname);

  const isActive = isFocused || isMenuOpened || isProfileActive;

  return (
    <div
      className={classNames(classes.root, TOUR_SELECTOR_KEYS.PROFILE_MENU)}
      {...focusWithinProps}
    >
      <Tooltip title={intl.formatMessage(messages.profileMenu)} arrow>
        <button
          className={classes.button}
          type="button"
          ref={forwardedRef}
          onClick={() => setIsMenuOpened(true)}
          aria-controls={menuId}
          aria-haspopup="menu"
          aria-label={self ? getUserFullName(self) : ''}
        >
          {!isDrawerMenuEnabled || isDesktop ? (
            <UserAvatar
              user={self}
              variant={small}
              layoutClass={classNames({
                [classes.avatarActive]: isActive,
              })}
            />
          ) : null}
          <MenuIcon classes={{ root: classes['icon-mobile'] }} />
          <ChevronIcon
            up={isMenuOpened}
            down={!isMenuOpened}
            className={classes['icon-desktop']}
          />
        </button>
      </Tooltip>
    </div>
  );
};

export default memo<Props>(withForwardRef(DrawerMenuButton));
