/* @flow */

import 'isomorphic-fetch';

import { isFunction } from '@braindate/util/lib/type';

import { getAuthenticationToken } from 'src/shared/app/authentication/selector/base/appAuthenticationBaseSelectors';
import { loginPath } from 'src/shared/app/base/route/setting/routePaths';
import {
  getAPIURL,
  getEnvironment,
  getVersion,
  isErrorReportingEnabled,
} from 'src/shared/app/base/selector/appEnvSelectors';
import { unregisterSentryUserFromBrowser } from 'src/shared/app/base/util/sentryUtils';
import { removeTokenFromCookie } from 'src/shared/app/base/util/tokenUtils';
import { serializeUrl } from 'src/shared/core/util/urlUtil';
import { getEventId } from 'src/shared/domain/event/selector/eventSelectors';

type methods = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

function getUrl(getState, url) {
  const extractUrl = () => {
    if (isFunction(url)) {
      return url(getAPIURL(getState()), getEventId(getState()));
    }
    return url;
  };
  return extractUrl().replace(/[0-9]+/g, 'X');
}

function doFetch(
  getState: () => Object,
  url: any,
  data?: Object,
  method?: methods = 'GET',
) {
  return _fetch(getState, url, data, method);
}

// HTTP methods
export function get(
  getState: () => Object,
  url: any,
  data?: Object,
): Promise<Response> {
  return doFetch(getState, url, data);
}

export function post(
  getState: () => Object,
  url: any,
  data?: Object,
): Promise<Response> {
  return doFetch(getState, url, data, 'POST');
}

export function patch(
  getState: () => Object,
  url: any,
  data?: Object,
): Promise<Response> {
  return doFetch(getState, url, data, 'PATCH');
}

export function put(
  getState: () => Object,
  url: any,
  data?: Object,
): Promise<Response> {
  return doFetch(getState, url, data, 'PUT');
}

export function deleteFn(
  getState: () => Object,
  url: any,
  data?: Object = {},
): Promise<Response> {
  return doFetch(getState, url, data, 'DELETE');
}

export function smartGet(
  getState: () => Object,
  url: any,
  data?: Object,
): Promise<Object> {
  return doFetch(getState, url, data, 'GET').then(parseResponse);
}

export function smartPost(
  getState: () => Object,
  url: any,
  data?: Object,
): Promise<Object> {
  return doFetch(getState, url, data, 'POST').then(parseResponse);
}

export function smartDelete(
  getState: () => Object,
  url: any,
  data?: Object,
): Promise<Object> {
  return doFetch(getState, url, data, 'DELETE').then(parseResponse);
}

export type BraindateError = Error & {
  status: string,
};

// Parse

export function parseResponse(response: Response): Promise<any> {
  const { url, status, statusText } = response;
  // If status code is 204, don't parse it to JSON or it causes an error. Return
  // an empty object instead of the response, otherwise actions may try to
  // save the response directly in the redux state, which will throw an
  // exception

  if (status === 204) {
    return new Promise((resolve) => {
      resolve({});
    });
  }
  if (status >= 200 && status < 300) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    }
    return response.text();
  }

  const errorName = 'APIError';

  return response
    .json()
    .catch((e) => {
      const error = new Error(
        `Failed to parse JSON response from ${url}
          Status: ${status} ${statusText}
          Original error: ${e.toString()}`,
      );

      error.name = errorName;
      // $FlowIssue
      error.status = status;
      // $FlowIssue
      error.url = url;

      throw error;
    })
    .then((json) => {
      const error = new Error(
        `Call to ${url} failed
          Status: ${status} ${statusText}
          With message: ${JSON.stringify(json)}`,
      );

      error.name = errorName;
      // $FlowIssue
      error.status = status;
      // $FlowIssue
      error.url = url;
      // $FlowIssue
      error.json = json;

      throw error;
    });
}

/*
|------------------------------------------------------------------------------
| PRIVATE FUNCTIONS
|------------------------------------------------------------------------------
*/

const _fetch = (
  getState: () => Object,
  url: any,
  data?: Object,
  method?: methods = 'GET',
): Promise<Response> => {
  const params = {
    method,
    headers: {},
  };

  const state = getState();
  const token = getAuthenticationToken(state);
  const { language } = state.app;

  let _url =
    typeof url === 'function'
      ? url(getAPIURL(state), getEventId(getState()))
      : url;

  if (token) {
    params.headers.Authorization = `Token ${token}`;
  }

  if (language) {
    params.headers['Accept-Language'] = language;
  }

  // Update user agent for calls made to the API by the node server
  if (typeof window === 'undefined') {
    params.headers['User-Agent'] = `${getVersion(state)} ${getEnvironment(
      state,
    )}`;
  }

  if (data && typeof data === 'object') {
    if (method === 'GET') {
      _url = serializeUrl(_url, data);
    } else if (typeof window !== 'undefined' && data instanceof FormData) {
      // $FlowIssue
      params.body = data;
    } else {
      params.headers['Content-Type'] = 'application/json';

      // $FlowIssue
      params.body = JSON.stringify(data);
    }
  }

  return fetch(_url, params).then((response: Response) => {
    const { status, url: responseUrl } = response;
    const errorName = 'Fetch error';
    // If invalid token (401 UNAUTHORIZED)
    if (status === 401) {
      if (typeof window !== 'undefined') {
        removeTokenFromCookie();

        if (isErrorReportingEnabled(state)) {
          unregisterSentryUserFromBrowser();
        }

        // Using `getRoutePath(loginRoute);` with `loginRoute` was creating
        // a cycle dependency problem here
        if (window.location.pathname !== loginPath) {
          window.location = loginPath;
        }
      } else {
        const error = new Error('Invalid token');

        // $FlowIssue
        error.name = errorName;
        // $FlowIssue
        error.status = status;
        // $FlowIssue
        error.url = responseUrl;

        throw error;
      }
    }

    return response;
  });
};
