/* @flow */

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useIntl } from 'react-intl';

import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { newTopicRoute } from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/nav/l10n/navigationL10n';
import LazyNewTopicMain from 'src/shared/app/topic/new/component/LazyNewTopicMain';
import LinkButton from 'src/shared/ui/component/link/LinkButton';

import styles from './MainTopBarCreateTopicButton.style';

const useStyles = createUseThemeStyles(styles);

const MainTopBarCreateTopicButton = () => {
  const classes = useStyles();
  const intl = useIntl();

  const [newTopicPath] = useRoutes(newTopicRoute);

  return (
    <LinkButton
      to={newTopicPath}
      layoutClass={classes.root}
      variant="primary"
      preloadComp={LazyNewTopicMain}
    >
      <ControlPointIcon />
      {intl.formatMessage(messages.newTopic)}
    </LinkButton>
  );
};

export default MainTopBarCreateTopicButton;
