/* @flow */

// Ref: https://martinfowler.com/articles/feature-toggles.html

// Experiment features
export const onboardingIntroAFeature = 'onboarding_intro_a';
export const marketPromptsFeature = 'market_prompts';
export const helpFeature = 'help';
export const githubProfileFeature = '18.11_github_profile';
export const lightThemeFeature = 'light_theme';
export const provideFeedbackSectionFeature =
  '22.05_show_provide_feedback_section';
export const accessibilityCompatibleFeature = '22.10_accessibility_compatible';
export const topicRecommendationFeature = 'recommendation';
export const smsPhoneReminderWidget = 'sms-phone-reminder-widget';
export const hideTopBarOnScroll = '23.08_topbar_hide_on_scroll';
export const inspirationTopicsFeature = '23.09_inspiration_topics';
export const drawerMenuFeature = 'drawer_menu';
export const pushNotificationsFeature = 'push_notifications';
export const topicReactionsFeature = 'topic_reactions';
export const commentsFeature = 'comments';
export const preselectedKeywordsFeature = 'custom_keywords';
export const reactionsCountFeature = 'show_reactions_count';
export const topicSuggestionsFeature = 'topic_suggestions';

// Integration features
export const hideAccountPrefsFeature = '18.10_hide_account_prefs';

// Plugins
export const emailPlugin = 'email';
export const smsPlugin = 'sms';
export const signupPlugin = 'signup';
export const superGroupPlugin = 'super_group';
export const slackPlugin = 'slack';
export const iframeIntegrationPlugin = 'iframe_integration';
export const topicDisclaimerPlugin = 'topic_disclaimer';
export const googleSyncPlugin = 'google-calendar';
export const outlookSyncPlugin = 'microsoft-outlook';
export const doubleDutchPlugin = 'doubledutch';
export const ssoPlugin = 'sso';
export const topicApprovalPlugin = 'topic_approval';
export const klikPlugin = 'klik_profile';
export const customMenuPlugin = 'custom_menu';
export const videoCallPlugin = 'video-call';
export const videoCallPluginProviderTeams = 'microsoft-teams';
export const videoCallPluginProviderTwilio = 'twilio';
export const showcasePagePlugin = 'showcase_page';
export const sponsorshipPlugin = 'sponsorship';
export const customImpactSurvey = 'custom_impact_survey';
export const topicCardCustomizationPlugin = 'customized_topic_card';
export const conversationFormatsPlugin = 'braindate_format';
export const fishbowlPlugin = 'fishbowl';
export const virtualBraindateRecordingPlugin = 'recordings';
export const redirectionPlugin = 'redirection';
export const cheerPlugin = 'cheers';
export const transcribePlugin = 'transcribe';
export const peopleTabDisabled = 'people_tab_disabled';

// Sponsorship plugin levels
export const marketHeaderDesktopLevel = 'marketplace-card';
export const marketHeaderMobileLevel = 'marketplace-header';
export const marketFooterLevel = 'marketplace-footer';
export const loginSplashLevel = 'splash';
export const mainNavMenuLevel = 'navbar';
export const emailLevel = 'email';
export const videoCallLevel = 'video-call';
export const cheerModalLevel = 'cheer-modal';

// Integration plugins
export const avatarUploadPlugin = '19.08_avatar_upload';
