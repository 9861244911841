/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import useMounted from 'src/shared/app/base/hook/useMounted';
import useReduxState from 'src/shared/app/base/hook/useReduxState';
import { isPermissionScreenShown } from 'src/shared/app/base/route/util/routeUtilsSelectors';
import NotificationContainerDumb from 'src/shared/app/notification/component/NotificationContainerDumb';

import useNotificationPortal from './hook/useNotificationPortal';

const NotificationPortal = (): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  const isMounted = useMounted();
  const { queue } = useNotificationPortal();
  const state = useReduxState();
  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  if (isMounted && !isPermissionScreenShown(state)) {
    return <NotificationContainerDumb queue={queue} />;
  }

  return null;
};

export default memo<{}>(NotificationPortal);
