/* @flow */

import Cookies from 'js-cookie';

import type { BraindateEvent } from '@braindate/domain/lib/event/type';

import { iframeIntegrationPlugin } from 'src/shared/app/feature/settings/featureSettings';

import { isPluginEnabled } from 'plugin/base/util/pluginUtils';

export function isTokenInCookie(): boolean {
  return !!Cookies.get('token');
}

export function saveTokenInCookie(
  token: string,
  secure?: boolean,
  event: BraindateEvent,
): void {
  const isIframeIntegrationEnabled = isPluginEnabled(
    iframeIntegrationPlugin,
    event,
  );
  Cookies.set('token', token, {
    expires: 365,
    path: '/',
    secure,
    sameSite: secure && isIframeIntegrationEnabled ? 'None' : 'Lax',
  });
}

export function getTokenInCookie(): string | null {
  return Cookies.get('token') || null;
}

export function removeTokenFromCookie(): void {
  Cookies.remove('token', { path: '/' });
}
