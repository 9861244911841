/* @flow */

import { either } from 'ramda';

import {
  getEventLoginURL,
  getEventProfileRequiredFields,
} from '@braindate/domain/lib/event/util';
import {
  isMembershipOnboardingCompleted,
  isMembershipProfileCompleted,
} from '@braindate/domain/lib/membership/util';
import {
  areAllUserSignupFieldsFilled,
  isUserEmailValidated,
} from '@braindate/domain/lib/user/util';
import { hasURLProtocol } from '@braindate/util/lib/url';

import {
  getAuthenticationToken,
  getInitialUrl,
  hasLoggedInFromBos,
} from 'src/shared/app/authentication/selector/base/appAuthenticationBaseSelectors';
import { getPathWithSearchString } from 'src/shared/app/authentication/util/urlUtils';
import { getMembership } from 'src/shared/app/base/api/endpoint/membershipEndpoint';
import { getUser } from 'src/shared/app/base/api/endpoint/usersEndpoint';
import {
  homeRoute,
  loginRoute,
  signupRoute,
  validateEmailRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import { isSplashScreenShown } from 'src/shared/app/base/route/util/routeUtilsSelectors';
import { getLocale } from 'src/shared/app/locale/selector/localeSelectors';
import { isOptinDone } from 'src/shared/app/permission/util/permissionUtils';
import type { State } from 'src/shared/core/type/reduxTypes';
import {
  getEvent,
  getEventSafely,
} from 'src/shared/domain/event/selector/eventSelectors';

import { getKlikProfileURL } from 'plugin/klik/util/klikUtils';
import { isLoginShownBeforeSignup } from 'plugin/signup/util/signupUtils';

import type { Location } from 'react-router';

const debug = require('debug')('redirectUtils');

export function standardAuthenticatedPageRedirect(
  state: State,
  config: Object,
  location: Location,
): ?string {
  const redirectTo = either(
    either(
      redirectToLoginUnlessAuthenticated,
      redirectToEmailValidationUnlessValidated,
    ),
    redirectToSignupUnlessCompleted,
  )(state, config, location);
  if (redirectTo && redirectTo.includes(location.pathname)) return;
  return redirectTo;
}

export function redirectToLoginUnlessAuthenticated(
  state: State,
  _config: Object,
  location: Location,
): ?string {
  if (!getAuthenticationToken(state)) {
    return getPathWithSearchString(location, loginRoute);
  }
}

export function redirectWhenRedirectAndAuthenticated(state: State): ?string {
  const initialURL = getInitialUrl(state);

  if (initialURL && getAuthenticationToken(state)) {
    return initialURL;
  }
}

export function redirectToEmailValidationUnlessValidated(
  state: State,
  { areEmailNotificationsEnabled }: Object,
  location: Location,
): ?string {
  if (!areEmailNotificationsEnabled || hasLoggedInFromBos(state)) return;
  const { data: self } = getUser.select('me')(state);
  if (!self || isUserEmailValidated(self)) return;
  return getPathWithSearchString(location, validateEmailRoute);
}

export function redirectToLoginIfOnboardingCompletedOnDevice(
  state: State,
  _config: Object,
  location: Location,
): ?string {
  if (hasLoggedInFromBos(state)) return;
  if (isSplashScreenShown(state, getEventSafely(state))) return;

  if (!getAuthenticationToken(state)) {
    return getPathWithSearchString(location, loginRoute);
  }
}

function mustShowSignup(state, isSignupEnabled) {
  const user = getUser.select('me')(state).data;
  const membership = getMembership.select()(state).data;
  const privateEvent = getEvent(state);
  const locale = getLocale(state);

  // User is not logged in
  if (!membership) {
    // If signup is not enabled and user is not logged in, it will show login page
    return isSignupEnabled;
  }

  // User is logged in but has not completed onboarding
  const membershipCompleted =
    isMembershipOnboardingCompleted(membership) && isOptinDone(membership);
  if (!membershipCompleted) {
    return true;
  }

  // User is logged in, has completed onboarding, has filled all required fields
  if (privateEvent && user) {
    const userFieldsFilled = areAllUserSignupFieldsFilled(
      user,
      privateEvent,
      locale,
    );

    if (isMembershipProfileCompleted(membership) || userFieldsFilled) {
      return false;
    }
  }

  const requiredFields =
    privateEvent && getEventProfileRequiredFields(privateEvent);

  // User is logged in, has completed onboarding, has not filled all required fields
  if (isSignupEnabled || (requiredFields && requiredFields.length > 0)) {
    return true;
  }
}

export function redirectToSignupUnlessCompleted(
  state: State,
  config: Object,
  location: Location,
): ?string {
  if (hasLoggedInFromBos(state)) return;
  if (isSplashScreenShown(state, getEventSafely(state))) return;

  const { isSignupEnabled } = config || { isSignupEnabled: false };

  const membership = getMembership.select()(state).data;
  if (
    !membership &&
    isSignupEnabled &&
    isLoginShownBeforeSignup(getEventSafely(state))
  ) {
    return getPathWithSearchString(location, loginRoute);
  }

  const showSignup = mustShowSignup(state, isSignupEnabled);
  if (showSignup) {
    debug('redirectToSignupUnlessCompleted');
    return getPathWithSearchString(location, signupRoute);
  }
}

export function redirectToHomeWhenSignupCompleted(
  state: State,
  config: Object,
  location: Location,
): ?string {
  const { isSignupEnabled } = config || { isSignupEnabled: false };

  if (!mustShowSignup(state, isSignupEnabled)) {
    debug('redirectToHomeWhenSignupCompleted');
    return getPathWithSearchString(location, homeRoute);
  }
}

export function redirectToHomeWhenAuthenticated(
  state: State,
  _config: Object,
  location: Location,
): ?string {
  if (getAuthenticationToken(state)) {
    debug('redirectToHomeWhenAuthenticated');
    return getPathWithSearchString(location, homeRoute);
  }
}

export function redirectToHomeWhenEmailValidated(
  state: State,
  { areEmailNotificationsEnabled, isSignupEnabled }: Object,
  location: Location,
) {
  const { data: self } = getUser.select('me')(state);
  if (!areEmailNotificationsEnabled || (self && isUserEmailValidated(self))) {
    debug('redirectToHomeWhenEmailValidated');
    return getPathWithSearchString(
      location,
      mustShowSignup(state, isSignupEnabled) ? signupRoute : homeRoute,
    );
  }
}

export function redirectToLoginURL(state: State): ?string {
  const loginURL = getEventLoginURL(getEventSafely(state));

  if (loginURL && hasURLProtocol(loginURL)) {
    return loginURL;
  }
}

export function integrationCustomRedirect(state: State): ?string {
  return getKlikProfileURL(getEventSafely(state));
}

export function standardUnauthenticatedPageRedirect(
  state: State,
  config: Object,
  location: Location,
): ?string {
  debug('standardUnauthenticatedPageRedirect');
  return redirectToHomeWhenAuthenticated(state, config, location);
}
