/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import type { Notification } from '@braindate/domain/lib/notification/type';
import {
  getNotificationDate,
  getNotificationId,
  getNotificationIsRead,
  getNotificationMessage,
  getNotificationPath,
  getNotificationSender,
  getNotificationTemplate,
} from '@braindate/domain/lib/notification/util';

import {
  useDismissNotificationMutation,
  useMarkNotificationAsReadMutation,
} from 'src/shared/app/base/api/endpoint/notificationsEndpoint';
import DateTimeComp from 'src/shared/app/base/date/component/DateTime';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/notification/l10n/notificationL10n';
import { notificationsMapTemplatesSettings } from 'src/shared/app/notification/type/notificationTypes';
import UserAvatar, {
  small,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import trackingEvents from 'src/shared/core/service/tracking/trackingEvents';
import CheerIcon from 'src/shared/ui/component/icon/CheerIcon';
import CrossIcon from 'src/shared/ui/component/icon/CrossIcon';
import GroupBraindateDecorationIcon from 'src/shared/ui/component/icon/GroupBraindateDecorationIcon';
import NotificationBadge from 'src/shared/ui/component/messaging/NotificationBadge';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './NotificationCard.style';

type Props = {
  notification: Notification,
  onClick: () => void,
};

const useStyles = createUseThemeStyles(styles);

const NotificationCard = ({ notification, onClick }: Props): Node => {
  const intl = useIntl();

  const [markAsRead, { isLoading: isMarkingAsRead }] =
    useMarkNotificationAsReadMutation();
  const [dismiss, { isLoading: isDismissing }] =
    useDismissNotificationMutation();

  const id = getNotificationId(notification);
  const path = getNotificationPath(notification);
  const isRead = getNotificationIsRead(notification);
  const sender = getNotificationSender(notification);
  const dateTime = getNotificationDate(notification);
  const template = getNotificationTemplate(notification);

  const elementId = `notification-card-${id}`;
  const templateSetting = notificationsMapTemplatesSettings[template];

  const classes = useStyles({
    unread: !isRead,
    isFetching: isDismissing || isMarkingAsRead,
  });

  const handleReadNotification = () => {
    trackingEvents.notification.click('Card');

    markAsRead(notification);
  };

  const handleOnClick = () => {
    trackingEvents.notification.click('Card');

    markAsRead(notification);
    onClick();
  };

  const handleDismiss = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dismiss(notification);
  };

  const showBraindateIcon = template.includes('group-');
  const showCheersIcon = template.includes('cheers-');

  const getIcon = () => {
    if (sender) {
      return <UserAvatar variant={small} alt="" user={sender} />;
    }
    if (showBraindateIcon) {
      return <GroupBraindateDecorationIcon />;
    }
    if (showCheersIcon) {
      return <CheerIcon />;
    }
    return null;
  };

  return (
    <div className={classes.root} id={elementId}>
      {templateSetting ? (
        <div className={classes.top}>
          <div className={classes.type}>
            <span className={classes.notificationIcon}>
              {templateSetting && templateSetting.icon}
            </span>{' '}
            <span className={classes.firstLetter}>
              {intl.formatMessage(templateSetting.title)}
            </span>
          </div>
        </div>
      ) : null}

      <Link className={classes.link} to={path} onClick={handleOnClick}>
        <span className={classes.avatar}>{getIcon()}</span>

        <div className={classes.text}>
          <div className={classes.notificationMessage}>
            {getNotificationMessage(notification)}
          </div>
          <div className={classes.date}>
            <DateTimeComp date={dateTime} />
          </div>
        </div>
      </Link>

      {isRead ? (
        <button
          onClick={handleDismiss}
          className={classes.actionButton}
          aria-label={intl.formatMessage(messages.dismiss)}
          aria-controls={elementId}
          disabled={isDismissing}
        >
          <CrossIcon />
        </button>
      ) : (
        <ConditionalWrapper
          condition={!isMarkingAsRead}
          wrapper={(child) => (
            <Tooltip title={intl.formatMessage(messages.markAsRead)}>
              {child}
            </Tooltip>
          )}
        >
          <button
            data-place="left"
            className={classes.actionButton}
            type="button"
            disabled={isMarkingAsRead}
            onClick={handleReadNotification}
          >
            <NotificationBadge />
          </button>
        </ConditionalWrapper>
      )}
    </div>
  );
};

export default memo<Props>(NotificationCard);
