/* @flow */

import { tablet } from '@braindate/ui/lib/token/mediaQuery';

import { templateMain, templateRoot } from './style/template.style';

export default {
  root: {
    extend: templateRoot,
    paddingTop: '0 !important',
  },
  main: {
    extend: templateMain,
    [tablet]: {
      maxWidth: '100%',
    },
  },
  icon: {
    minWidth: '100%',
    width: '100%',
    height: 'auto',
  },
};
