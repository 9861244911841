/* @flow */

import { pressDuration } from '@braindate/ui/lib/token/animation';

import { minTargetSize } from 'src/shared/app/base/style/token/box';

export default {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: minTargetSize,
    height: minTargetSize,

    transformOrigin: 'center center',
    transition: `transform ${pressDuration}`,
    color: ({ theme, isReverseColor }: Object) =>
      isReverseColor ? theme.textColorReverse : theme.accentColor,

    '&:hover': {
      transform: 'scale(1.15, 1.15)',
    },

    '&:active': {
      transform: 'scale(0.85, 0.85)',
    },
  },
};
