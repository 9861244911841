/* @flow */

import * as Sentry from '@sentry/browser';

const debug = require('debug')('errorLog');

/**
 * Get the Sentry options for configuration
 * @return {Object} Options
 */
export function getSentryOptions(): Object {
  const options = {
    dsn: process.env.SENTRY_DSN,
    release: process.env.SENTRY_RELEASE,
    environment: process.env.SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
    ],
    tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE),
    // _whitelistUrls: errorReportingWhiteUrls,
    maxValueLength: 500,
    ignoreErrors: [
      'Non-Error promise rejection captured with value: Object Not Found Matching Id',
      'HTMLDocument.window.__trackAbandons(<unknown module>)',
    ],
  };
  debug('init options', options);

  return options;
}
