/* @flow */

import { DateTime, Zone } from 'luxon';

import { timeFormat12h } from 'src/shared/app/locale/settings/localeSettings';

export const dateFormatOpts = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
};

export const dateLongFormatOpts = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
};

export const datetimeFormatOpts = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const monthDayFormatOpts = {
  month: 'short',
  day: 'numeric',
};

export const weekDayFormatOpts = {
  weekday: 'short',
};

export const dayFormatOpts = {
  day: 'numeric',
};

export const longDayFormatOpts = {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
};

export const longWeekDayFormatOpts = {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
  year: 'numeric',
};

export const timeFormatOpts = {
  hour: 'numeric',
  minute: 'numeric',
};

export function formatDate(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(dateFormatOpts);
}

export function formatDateLong(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(dateLongFormatOpts);
}

export function formatDateInput(date: DateTime, locale: string): string {
  return date.setLocale(locale).toISODate();
}

export function formatMonthDay(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(monthDayFormatOpts);
}

export function formatTimeOfDate(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(timeFormatOpts);
}

export function formatWeekDay(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(weekDayFormatOpts);
}

export function formatDay(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(dayFormatOpts);
}

export function formatLongDay(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(longDayFormatOpts);
}

export function formatLongWeekDay(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(longWeekDayFormatOpts);
}

export function formatDateTime(
  date: DateTime,
  locale: string,
  timeFormat?: string,
  withLocalZone: boolean = false,
  dateTimeFormat: Object = datetimeFormatOpts,
): string {
  return setDateTimezone(date, withLocalZone)
    .setLocale(locale)
    .toLocaleString(
      setTimeFormatOption(
        {
          ...dateTimeFormat,
        },
        timeFormat,
      ),
    );
}

export function formatTime(
  date: DateTime,
  locale: string,
  timeFormat?: string,
  withLocalZone: boolean = false,
) {
  return setDateTimezone(date, withLocalZone)
    .setLocale(locale)
    .toLocaleString(
      setTimeFormatOption(
        {
          ...timeFormatOpts,
        },
        timeFormat,
      ),
    );
}

export function setTimeFormatOption(opts: Object, timeFormat?: string): Object {
  if (timeFormat) {
    // $FlowIssue
    opts.hourCycle = timeFormat === timeFormat12h ? 'h12' : 'h24';
  }

  return opts;
}

export function setDateTimezone(
  date: DateTime,
  withLocalZone: boolean,
): DateTime {
  if (withLocalZone) {
    const { instance: localZone } = Zone;

    if (localZone.isValid) {
      return date.setZone(localZone);
    }
  }

  return date;
}
