/* @flow */
import { em } from 'polished';

export default {
  root: {
    fontSize: 16,
    width: em(14),
    height: em(15),
  },
};
