/* @flow */

import { memo } from 'react';
import type { ComponentType, Node } from 'react';

import Container from 'src/shared/app/base/component/container/Container';
import TemplatePortals from 'src/shared/app/base/component/template/component/TemplatePortals';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './FullscreenTemplate.style';

const useStyles = createUseThemeStyles(styles);

type Props = {
  Switch: ComponentType<any>,
};

const WithFullscreenTemplate = ({ Switch }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */

  return (
    <div className={classes.root}>
      <TemplatePortals />

      <div className={classes.main}>
        <Container>
          <Switch />
        </Container>
      </div>
    </div>
  );
};

export default memo<Props>(WithFullscreenTemplate);
